<template>
  <router-link class="news-card grid-sm-4"
               :to="`${$locale.base}${link}`">
    <div class="news-card__wrapper">
      <Column>
        <div class="news-card__image-holder">
          <div class="news-card__image-link">
            <img :src="image"
                 class="news-card__image"
                 alt=""/>
          </div>
          <Favorite v-if="params.link && params.link.split('/').some(item => item === 'events')"
                    class="news-card__favorites"
                    :params="{id, entity, favorited}"/>
        </div>
        <div class="news-card__info-holder">
          <div class="news-card__info-type-container">
          <p v-if="params.type"
              class="news-card__info-type">
            {{$type(params.type.name ? params.type.name : params.type)}}</p>
          <div v-if="params.type === 'event'"
                class="news-card__info-type-event">
            <div v-html="svgImg()"/>
            {{badgeName}}
          </div>
          </div>
          <div class="news-card__info-title">{{title}}</div>
          <div class="news-card__info-address">
            {{address}}
          </div>
          <Row class="news-card__info-icon-date">
            <Icon class="news-card__info-icon"
                  viewport="0 0 20 20"
                  xlink="calender"/>
            <span class="news-card__info-date">
              {{params.started_at
              ? $moment.unix(params.started_at).format(formatDate)
              : $moment.unix(params.created_at).format(formatDate)}}</span>
          </Row>
        </div>
      </Column>
    </div>
  </router-link>
</template>

<script>
import errorImage from '@/assets/images/svg/error.svg';

export default {
  name: 'NewsCard',
  data() {
    return {
      formatDate: 'DD MMMM, HH:mm',
    };
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    id() {
      return this?.params?.id;
    },
    entity() {
      return this?.params?.entity;
    },
    favorited() {
      return this?.params?.favorited;
    },
    link() {
      return `/${this?.params?.entity}/${this?.params?.id}`;
    },
    badgeName() {
      const Badge = this.$badges.find((badge) => badge.id === this.params.badge_id);
      return Badge ? Badge.title : '';
    },
    title() {
      return this?.params?.title;
      // const count = this.$laptop ? 60 : 35;
      // return this.trim(this.params.title || this.params.name, count);
    },
    address() {
      return this?.params?.address;
      // const count = this.$laptop ? 70 : 35;
      // return this.trim(this.params.place ? this.params.place.address
      // : this.params.address, count);
    },
    image() {
      return this?.params.images[0] || errorImage;
    },
  },
};
</script>
